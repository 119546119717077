import { useState } from 'react';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { VFlex, HFlex, Link } from './Layout.jsx';
import CloseIcon from '@mui/icons-material/Close';
import IconCopyButton from '../components/IconCopyButton.jsx';
import { BrowserView, MobileView, } from 'react-device-detect';
import QRCode from "react-qr-code";
import { crv } from '../constants.js';

const donateUri = aid => `algorand://${crv}?amount=0${aid ? "&asset="+aid : ""}`;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(min(450px, 99vw))',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  pb: 2,
};

function P({ children }) {
  return <Typography sx={{mb: 1}}>
    {children}
  </Typography>
}

export default function DonateButton({ assetId=31566704, sx, ...props }) {
  const [open, setOpen] = useState();
  const toggleOpen = () => setOpen(s => !s);
  const mobileDonate = () => window.open(donateUri(assetId));
  return <>
    <Button variant="contained" {...props} sx={sx} onClick={toggleOpen} >DONATE</Button>
    <Modal open={open}>
      <Box sx={modalStyle}>
        <Box sx={{position: 'absolute', top: 5, right: 5}}>
          <IconButton onClick={toggleOpen}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h3" sx={{mb: 2}}>
          <center>
          Donate to the CRV
          </center>
        </Typography>
        <P>
          <center>
            <strong>We are currently raising USD for a <Link href="/CEX">CEX listing</Link></strong>
          </center>
        </P>
        <P>
          Donations to the CRV come with voting power in the DAO decision making.
        </P>
        <P>
          <HFlex grow={1} style={{justifyContent: 'flex-start'}}>
            Vault address:
            <HFlex grow={1} style={{justifyContent: 'flex-end'}}>
              <Link href={`https://allo.info/account/${crv}`}>coop.algo</Link> <IconCopyButton size="small" initColor="faded" value={crv} />
            </HFlex>
          </HFlex>
        </P>
        <P>
          <BrowserView>
            <VFlex sx={{mt: 2}}>
              <QRCode title="A" size={128} value={donateUri(assetId)} />
              <Typography sx={{mt: 1}}>
                <i>Scan with wallet to donate USDC</i>
              </Typography>
            </VFlex>
          </BrowserView>
          <MobileView>
            <HFlex>
              <Button variant="contained" onClick={mobileDonate}>DONATE</Button>
            </HFlex>
          </MobileView>
        </P>
      </Box>
    </Modal>
  </>
}
