import MobxCache from './mobx-cache.js';
import { getAssetInfo } from '../algo.js';

const assets = new MobxCache({
  name: 'assets',
  persist: true,
  onMiss: getAssetInfo,
  processData: data => {
    const { aid, "unit-name": unit, name, decimals } = data;
    return { aid, name, unit, decimals };
  },
});

export default assets
