function replacer(key, value) {
  if (typeof value === 'object' && value) {
    const object = value;
    for(const [key, value] of Object.entries(object)) {
      if(value instanceof Map || value?.data_ instanceof Map) {
        object[key] = {
          dtt: 'Map',
          val: Array.from(value.entries()),
        };
      }
    }
  }
  return value;
}

function reviver(key, value) {
  if(typeof value === 'object' && value !== null) {
    if (value.dtt === 'Map') {
      return new Map(value.val);
    }
  }
  return value;
}

export function load(type, saveFn, loadEmpty = false) {
  const encoded = localStorage.getItem(type);

  if (!encoded) {
    if (loadEmpty) {
      saveFn();
    }
    return;
  }

  try {
    saveFn(JSON.parse(encoded, reviver));
  } catch(e) {
    console.log(`While loading localStorage.${type}: ${e.message}`);
  }
}

export function save(type, val) {
  console.log('saving', type, val, Math.floor(Date.now() / 1000));
  try {
    const toSave = JSON.stringify(val, replacer);
    localStorage.setItem(type, toSave);
    return true;
  } catch(e) {
    console.error(`While saving localStorage.${type}: ${e.message}`);
    console.error('Value was:');
    console.error(val);
  }
}
