import promilol from 'promilol';
import { makeAutoObservable, runInAction, reaction } from 'mobx'
import { load, save } from './persist.js';
import { convertObjectSnakeCaseToCamelCase } from '../utils.js';
import constants from '../constants.json';
import get from 'lodash/get';
import { getBridgings } from '../algo/indexer.js';
import { wormholeNetworks } from '../constants.js';

const _type = 'lps';

const DEBUG = false;

const version = 1;

class LPs {
  data = null;
  chainStats = null;
  globalStats = null;
  lastUpdated = 0;

  constructor(assets) {
    makeAutoObservable(this);
    this.assets = assets;

    load(_type, ({data, version: v} = {}) => {
      if (version === v) {
        setTimeout(() => {
          runInAction(() => {
            this.data = data;
          });
        }, 1);
      }
    });

    reaction(() => this.data, () => {
      save(_type, { version, data: this.data });
    });

  }

  setData(data) {
    this.data = data;
  }

  async refresh(force) {
    const lastRefreshElapsed = (Date.now() - this.lastUpdated) / 1_000;
    if (!force && lastRefreshElapsed <= 120) {
      console.log('too soon');
      return this.data;
    }

    try {
      this.setLoading(true);
      const resp = await fetch('https://free-api.vestige.fi/asset/796425061/pools?include_all=true');
      const data = await resp.json();
      const lps = data.map(({address}) => address);
      lps.push("SRZLXTYKCN5HSQSOYIVVJ4WF5IGVPJ33IKTADRHGGM6GB3XYGTRAWQXEOM"); // wormhole
      lps.push("IN3O5H2TF2LDFO7UINLZOFMRJDCVODZAFAHKOURO566F5MKW7F6WA3PFGY"); // cometa
      lps.push("QTFSX7MJLUBP4TLCRNGMEQCOCG6Z2O5A7RAUREW5S5RYIGMGYDLDYYSMAQ"); // xbacked
      lps.push("IINKBOGC5UIWUSKRPSS26KQADHVWLG2STZ2UKFLH3427EBCMCHL3GER5UM"); // cometa
      this.setData(lps);
      this.setLoading(false);
      return lps;
    } catch(e) {
      console.error('While reloading', e.message);
      this.setLoading(false);
    }
  }

  setLoading(val) {
    this.loading = val;
  }
  
  setLastUpdated(val) {
    this.lastUpdated = val;
  }
  
  reset() {
    this.setLastUpdated(null);
    this.setLoading(false);
    this.setData(null);
  }

}

export default LPs;
