import algodClient from './algod-client.js';
import { snakeCaseToCamel } from '../utils.js';

export const convertStringKey = (key) => snakeCaseToCamel(atob(key));

export const convertIntKey2 = (key) => {
  return parseInt(Buffer.from(key, 'base64').slice(4).toString('hex'), 16);
}

export const convertIntKey = (key) => {
  // console.log(parseInt(Buffer.from(key, 'base64').toString('hex'), 16));
  // console.log(key);
  // debugger;
  // console.log('convertIntKey1', parseInt(Buffer.from(key, 'base64').toString('hex'), 16));
  // console.log('convertIntKey2', parseInt(atob(key).split('').map(s => s.charCodeAt(s)).join(''), 10));
  return parseInt(atob(key).split('').map(s => s.charCodeAt(0)).join(''), 10);
}

export const parseIntResult = (logs) => {
  if (!logs || !logs.length) {
    return;
  }
  const lastLog = logs[logs.length - 1];
  const value = lastLog.slice(4);
  const res = parseInt(UintArrayToHex(value), 16);
  return res;
}

export function UintEquals (a, b) { return a.length === b.length && a.every((byte, i) => b[i] === byte) };

export function UintArrayToHex(value) {
  return new Array(...value).map((n) => { const s = n.toString(16); if (s.length == 1) return `0${s}`; return s }).join('');
}

export const waitForConfirmation = async (txId, timeout = 15) => {
  if (txId == null || timeout < 0) {
    throw new Error("Bad arguments");
  }

  const status = (await algodClient.status().do());
  if (status === undefined) {
    throw new Error("Unable to get node status");
  }

  const startround = status["last-round"] + 1;
  let currentround = startround;

  while (currentround < (startround + timeout)) {
    const pendingInfo = await algodClient.pendingTransactionInformation(txId).do();
    if (pendingInfo !== undefined) {
      if (pendingInfo["confirmed-round"] !== null && pendingInfo["confirmed-round"] > 0) {
        //Got the completed Transaction
        return { id: txId, ... pendingInfo };
      } else {
        if (pendingInfo["pool-error"] != null && pendingInfo["pool-error"].length > 0) {
          // If there was a pool error, then the transaction has been rejected!
          throw new Error("Transaction " + txId + " rejected - pool error: " + pendingInfo["pool-error"]);
          // TODO friendlier - parse errors?
        }
      }
    }
    await algodClient.statusAfterBlock(currentround).do();
    currentround++;
  }

  throw new Error(`Transaction ${txId} was not confirmed after ${timeout} rounds`);
};
