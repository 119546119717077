import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import theme from './theme.js';
import { ThemeProvider } from '@mui/material/styles';
import Layout from './components/Layout.jsx';
import Fourohfour from './routes/Fourohfour';
import Home from './routes/Home';
import Gov from './routes/Gov';
import Holders from './routes/Holders';
import Donors from './routes/Donors';
import CEX from './routes/CEX';
import Bridge from './routes/Bridge';
import FAQ from './routes/FAQ.js';
import { account, lps, prices, holders, cex, donors, bridge, assets } from './state/';
import HistoryBridge from './history-bridge.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/" exact={true} element={<Home donors={donors} />} />
                <Route path="holders" element={<Holders holders={holders} lps={lps} />} />
                <Route path="donations" element={<Donors prices={prices} donors={donors} />} />
                <Route path="crv" element={<Donors prices={prices} donors={donors} />} />
                <Route path="cex" element={<CEX donors={cex} />} />
                <Route path="bridge" element={<Bridge bridge={bridge} assets={assets} />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="*" element={<Fourohfour />} />
              </Route>
            </Routes>
          </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
