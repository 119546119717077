import { useSnackbar } from 'notistack';

class Notifications {
  enqueueSnackbar;
  closeSnackbar;
  snackbarKeys = {};
  enqueue(variant, message, autohide = 4000, key, propsIn) {
    if (this.enqueueSnackbar) {
      if (key)
        if (this.snackbarKeys[key])
          this.closeSnackbar(key);
      const props = autohide < 1 ? { persist: true } : { autoHideDuration: autohide };
      const propsObj = { variant, ...props, ...propsIn };
      console.log(propsObj, message);
      const k = this.enqueueSnackbar(message, propsObj);
      if (key)
        this.snackbarKeys[key] = k;
    } else {
      alert(message);
    }
  }
  default(...props) {
    return this.enqueue('default', ...props);
  }
  info(...props) {
    return this.enqueue('info', ...props);
  }
  success(message, autohide = 5000, ...props) {
    return this.enqueue('success', message, autohide, ...props);
  }
  warning(message, autohide = 8000, ...props) {
    return this.enqueue('warning', message, autohide, ...props);
  }
  error(message, autohide = 12000, ...props) {
    this.enqueue('error', message, autohide, ...props);
  }
  closeSnackbar(key) {
    const actualKey = this.snackbarKeys[key];
    if (actualKey) {
      this.doCloseSnackbar(actualKey);
    } else {
      console.warn('snackbar key not found', key);
    }
  }
  closeAll() {
    for(const actualKey of Object.values(this.snackbarKeys)) {
      try {
        this.doCloseSnackbar(actualKey);
      } catch(e) {
      }
    }
  }
}

const notifications = new Notifications();

export const NotificationBridge = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  notifications.enqueueSnackbar = enqueueSnackbar;
  notifications.doCloseSnackbar = closeSnackbar;
}

export default notifications;
