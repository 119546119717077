import Account from './account';
import makeCollection from './collection.js';
import Holders from './holders.js';
import Donors from './donors.js';
import Cex from './cex.js';
import Bridge from './bridge.js';
import DoNotShowAgain from './do-not-show-again.js';
import Algorand from './algorand.js';
import History from './history.js';
import Prices from './prices.js';
import _assets from './assets.js';
import LPs from './lps.js';

export const account = new Account();
export const doNotShowAgain = new DoNotShowAgain();
export const algorand = new Algorand();

export const prices = new Prices();
export const donors = new Donors(prices);
export const cex = new Cex(prices);
export const bridge = new Bridge(_assets);
export const lps = new LPs();
export const holders = new Holders(lps);

export const assets = _assets;
