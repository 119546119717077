import promilol from 'promilol';
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { load, save } from './persist.js';
import { convertObjectSnakeCaseToCamelCase } from '../utils.js';
import constants from '../constants.json';
import get from 'lodash/get';
import { getBridgings } from '../algo/indexer.js';
import { wormholeNetworks } from '../constants.js';

const _type = 'bridge';

const DEBUG = false;

class Bridge {
  loading = false;

  data = null;
  chainStats = null;
  globalStats = null;
  lastUpdated = 0;

  constructor(assets) {
    makeAutoObservable(this);
    this.assets = assets;

    load(_type, (val) => { 
      if (val?.data)
        setTimeout(() => {
          this.setChainStats(val.chainStats)
          this.setGlobalStats(val.globalStats)
          this.setData(val.data)
          this.setLastUpdated(val.lastUpdated);
        }, 1);
    }, true);

    reaction(() => this.data, (newValue, prevValue) => {
      if (!save(_type, {
        data: this.data,
        globalStats: this.globalStats,
        chainStats: this.chainStats,
        lastUpdated: this.lastUpdated,
      })) {
        console.error('err');
      }
    });
  }

  setLoading(val) {
      this.loading = val;
  }

  setChainStats(data) {
    this.chainStats = data;
  }

  setGlobalStats(data) {
    console.log('globalStats', data);
    this.globalStats = data;
  }

  setLastUpdated(val) {
    this.lastUpdated = val;
  }

  setData(data) {
    console.log("setting bridging data", data);
    this.lastUpdated = Date.now();
    this.data = data;
  }

  async refresh(force) {
    if (this.loading) {
      console.log('already loading');
    }
    const lastRefreshElapsed = (Date.now() - this.lastUpdated) / 1_000;
    if (!force && lastRefreshElapsed <= 120) {
      console.log('too soon');
      return;
    }

    this.setLoading(true);

    try {

      let bridgings = (await getBridgings()).reverse();

      const globalStats = {};
      const chainStats = {};

      await promilol(bridgings, async entry => {
        let { chain, amount, account, aid, id } = entry;

        const g = globalStats[aid] = globalStats[aid] ?? scaffold();
        const cs = chainStats[aid] = chainStats[aid] ?? {};
        const c = cs[chain] = cs[chain] ?? scaffold();

        try {
          const { decimals } = await this.assets.wait(aid);
          entry.amount = amount /= 10 ** decimals;

          const label = amount > 0 ? 'out' : 'in';
          const absAmount = Math.abs(amount);

          g.num++
          g[label]++;
          g[`${label}Sum`] += absAmount;
          g[`netSum`] += amount;
          g.users.add(account);

          c.num++
          c[label]++;
          c[`${label}Sum`] += absAmount;
          c[`netSum`] += amount;
          c.users.add(account);
        } catch(e) {
          console.error('Error processing', id);
        }
      }, { concurrency: 25 });

      bridgings = bridgings.reduce((out, entry) => {
        const { aid } = entry;
        if (!out[aid])
          out[aid] = []
        out[aid].push(entry)
        return out;
      }, {});

      for(const gS of Object.values(globalStats)) {
        gS.users = gS.users.size;
      }

      const arrChainStats = Object.fromEntries(Object.entries(chainStats)
        .map(([aid, chainStats]) => {
          return [
            aid,
            Object.entries(chainStats)
              .map(([name, obj]) => {
                obj.name = name;
                obj.users = obj.users.size;
                return obj;
              }).sort((a, b) => a.netSum > b.netSum ? -1 : 1)
          ];
        })
      );

      this.setGlobalStats(globalStats);
      this.setChainStats(arrChainStats);
      this.setData(bridgings);
      this.setLoading(false);
    } catch(e) {
      console.error('While reloading', e.message);
      this.setLoading(false);
    }
  }
  
  reset() {
    this.setLastUpdated(null);
    this.setLoading(false);
    this.setData(null);
  }

}

export default Bridge;

function scaffold() {
  return { num: 0, out: 0, in: 0, outSum: 0, inSum: 0, netSum: 0, users: new Set() };
}

function scaffoldChains() {
  return Object.values(wormholeNetworks).reduce((obj, name) => {
    obj[name] = scaffold();
    return obj;
  }, {});
}
