import './Home.css';
import { useEffect } from 'react';
import { TeamHolder, VSeparator, Opaque, HFlex, VFlex, Left, Link } from '../components/Layout.jsx';
import { coopAid, ALGO, numLocaleOptionsFull, numLocaleOptions } from '../constants.js';
import { convertObjectSnakeCaseToCamelCase, printUSD } from '../utils.js';
import constants from '../constants.json';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate, } from 'react-router-dom';
import CopyButton from '../components/CopyButton.jsx';
import D13Icon from '../images/D13.png';
import Socials from '../components/Socials.jsx';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import YouTubeIcon from '@mui/icons-material/YouTube';

const cexLink = "https://www.probit.com/app/exchange/COOP-USDT";

const { rewardsAddress } = convertObjectSnakeCaseToCamelCase(constants);

function Info() {
  return <div className="info-container"> <InfoOutlinedIcon color="inherit" className="info" fontSize="inherit" /> </div>;
}

function SubTitle({ children, ...props }) {
  return <Typography align="center" variant="h2" sx={{mt: 4, mb: 1}}>{children}</Typography>;
}


function B({children}) {
  return <span className="maldini-bold">{children}</span>;
}

function H2({children}) {
  return <Typography variant="h2">{children}</Typography>;
}

function H3({children}) {
  return <Typography sx={{mb: 2}} variant="h3">{children}</Typography>;
}

const respMr = {
  mr: { xs: 0, md: 1},
  mt: 1,
}

export default observer(function({ donors }) {
  const navigate = useNavigate();

  useEffect(() => {
    donors.refreshDonors();
  }, []);

  return <>

    <VSeparator vh={2} />

    <Opaque h2 op={0.75}>
      <H2><B>COOP</B> COIN</H2>
    </Opaque>

    <VSeparator vh={1} />

    <Opaque h2 op={0.75}>
      <H2>On <B>{ALGO}lgorand</B></H2>
    </Opaque>

    <VSeparator vh={1} />

    <HFlex sx={{position: 'relative'}}>
      <Box className="blink maldini-style" sx={{textAlign: 'center', position: 'absolute', top: '75px', right:'5%', lineHeight: "2.25rem", fontSize: '2.5rem', transform: 'rotate(-15deg)'}}>
        <Link style={{textDecoration: 'none', paddingLeft: "0.5rem", paddingRight: "0.5rem", paddingTop: '0.25rem',}} href={cexLink}>Now on CEX</Link>
      </Box>
    </HFlex>

    <VSeparator vh={{xs: '27vh', md: '33vh' }}/>

    <Opaque sx={{ position: 'relative' }}>
      <VFlex>
        <H3><B>Coop coin</B> Genesis</H3>
        <Left>
          <Typography><strong>$COOP was created by Cooper Daniels</strong> and fully distributed as a surprise to anyone who had opted in.</Typography>
          <Typography>Until that time it was believed that each person would only be given a single $COOP.</Typography>
          <Typography>This was not announced or expected. Cooper kept exactly <strong>zero</strong> $COOP for himself.</Typography>
          <hr />
          <Typography><strong>It should have gone to zero and stayed there</strong>, as there was not even a token roadmap in place.</Typography>
          <Typography>But the Algorand commuinity reacted with interest.</Typography>
          <Typography>Liquidity Pools were created. Some paper-handed it, others gobbed it up.</Typography>
          <Typography>$COOP was the talk of the town, and community members started building around it.</Typography>
          <hr />
          <Typography><strong>You can buy $COOP</strong> through the Vestige DEX aggregator on Algorand, or on the Probit exchange:</Typography>
        </Left>
        <HFlex sx={{mt: 1, flexDirection: { xs: 'column', sm: 'row' }}}>
          <CopyButton sx={respMr} changeLabel={false} copyIcon={true} initLabel={`ID ${coopAid}`} value={coopAid} />
          <Button sx={respMr} variant="outlined" startIcon={<CandlestickChartIcon />} onClick={() => window.location.href=`https://vestige.fi/asset/${coopAid}`}>VESTIGE.FI</Button>
          <Button sx={respMr} variant="outlined" startIcon={<CandlestickChartIcon />} onClick={() => window.location.href=cexLink}>PROBIT.COM</Button>
          <Button sx={respMr} startIcon={<YouTubeIcon />} onClick={() => window.location.href=`https://www.youtube.com/@TheReCoop`}>THE RECOOP</Button>
        </HFlex>
      </VFlex>
    </Opaque>

    <VSeparator vh={{ xs: '5vh', md: '20vh'}} />
    <Opaque sx={{ position: 'relative' }}>
      <VFlex sx={{ maxWidth: '740px'}}>
        <H3><B>CRV</B> Community Rewards Vault</H3>
        <Left>
          <Typography>The CRV is a community initiative to pool $COOP and liquidity pool tokens.{ donors.numDonations ? <> It has received {donors.numDonations} donations by {donors.data.length} community members, totalling {printUSD(donors.totalUSD)}.</> : null }</Typography>
          <hr />
          <Typography><strong>This capital will be deployed towards bringing longevity and utility to $COOP according to the decisions of the COOP DAO.</strong></Typography>
          <hr />
          <Typography>The COOP DAO is an informal structure based on the USD value of donations. Proposals and votes take place on our <Link href="https://discord.gg/JTeUBuV3qM">Discord</Link>. If you are a donor, please join and get involved!</Typography>
        </Left>
        <Button sx={{mt: 2}} onClick={() => navigate("/donations")} variant="outlined">CRV STATS & DONATIONS</Button>
      </VFlex>
    </Opaque>

    <VSeparator vh={{ xs: '5vh', md: '20vh'}} />
    <Opaque sx={{ position: 'relative' }}>
      <VFlex sx={{ maxWidth: '740px'}}>
        <H3><B>Transparency</B> Report 2023</H3>
        <Left>
          <Typography>The CRV has published a transparency report for its 2023 activities.</Typography>
          <hr />
          <Typography>In it, we break down the total amounts donated by our 130 individual donors, as well as all expenditures and LP deals.</Typography>
          <hr />
          <Typography>The report is available below in HTML and PDF.</Typography>
        </Left>
        <HFlex sx={{mt: 1, flexDirection: { xs: 'column', sm: 'row' }}}>
          <Button sx={respMr} variant="outlined" startIcon={<BarChartIcon />} onClick={() => window.location.href=`/transparency-report-2023/`}>2023 REPORT (HTML)</Button>
          <Button sx={respMr} startIcon={<PieChartIcon />} onClick={() => window.location.href=`/transparency-report-2023/crv-transparency-report-2023.pdf`}>2023 REPORT (PDF)</Button>
        </HFlex>
      </VFlex>
    </Opaque>

    <VSeparator vh={{ xs: '5vh', md: '20vh'}} />

    <Opaque id="socials">
      <H3>Get <B>Social</B></H3>
      <Socials />
    </Opaque>

    <VSeparator />
  </>;
});



     //  <!-- <Typography>Our white-paper outlining the design of CupStakes in more detail can be found <Link href="https://d13.co/uploads/temp/algofam/cupstakes.pdf">[here]</Link></Typography> -->
/*
    <VSeparator />

    <Opaque>
      <H3>Affiliations &amp; Endorsements</H3>
      <Left>
        <Typography>Our Trust partners act as individuals and their participation should not be considered an endorsement by their respective employers or organisations</Typography>
        <Typography>CupStakes is not affiliated with FIFA, the Algorand Foundation or Algorand inc.</Typography>
        <Typography>CupStakes does not misuse any registered trademarks.</Typography>
      </Left>
    </Opaque>
          */
