import { load, save } from './persist';
import { makeAutoObservable, reaction } from "mobx"
import Explainer from '../components/Explainer.jsx';

const _type = 'doNotShowAgain';

export default class DoNotShowAgain {
  burn = null
  collect = null

  constructor(newObj) {
    makeAutoObservable(this);

    load(_type, (data) => {
      for(const [key, val] of Object.entries(data)) {
        this.set(key, val);
      }
    });

    for(const key of Object.keys(this)) {
      if (typeof this[key] !== 'function') {
        reaction(() => this[key], (newValue, prevValue) => {
          console.log(key,'changed',prevValue,'->',newValue);
          save(_type, this);
        });
      }
    }
  }

  set(key, value) {
    this[key] = value;
  }

  renderOnce = ({ uniqueKey, title, explainer, callback, setModal }) => {
    if (!this[uniqueKey]) {
      const callbackAndWrite = (doNotShowAgain) => {
        this.set(uniqueKey, doNotShowAgain);
        callback();
      }
      return <Explainer title={title} onClose={callbackAndWrite} setModalParent={setModal} >
        {explainer}
      </Explainer>;
    }
    callback();
    return null;
  }

  renderAwait = ({ setModal, ...props }) => {
    let resolve;
    const promise = new Promise((_resolve) => resolve = _resolve);
    const callback = async () => {
      try {
        await props.callback();
      } catch(e) {
      } finally {
        resolve();
      }
    }
    setModal(this.renderOnce({ ...props, setModal, callback }));
    return promise;
  }

}
