import coopAssetConfigs from './assets.json';

export const getCoopAssetUSDPrices = async () => {
  const prices = {};
  for(const {name, aid} of coopAssetConfigs) {
    const peraUSD = await getPeraUSD(aid);
    prices[aid] = peraUSD;
  }
  return prices;
}

async function getPeraUSD(aid) {
  const resp = await fetch(`https://api.perawallet.app/v1/assets/${aid}/`);
  const json = await resp.json();
  const num = parseFloat(json.usd_value);
  if (isNaN(num)) {
    throw new Error('USD value not found');
  }
  return num;
}

export async function getAlgoUSDPrice() {
  const response = await fetch('https://free-api.vestige.fi/currency/prices');
  const { USD }  = await response.json();
  return USD;
}
