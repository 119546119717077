import algosdk from 'algosdk'
import { uint8ToBase64, } from './utils.js';

export const ALGO = 'Ⱥ';

export const numLocaleOptions = { maximumFractionDigits: 2 };

export const numLocaleOptionsFull = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

export const afterRoyalT = 1670598066000;

export const voteAddress = 'IDONTKNOWWHATCOOPCOINISANDATTHISPOINTIAM2AFRAIDTOASKN62URM';

export const votePrefixNS = 'coopcrv/v1';
export let govStart = 1685127600;
export const govEnd = 1685300400;

export const crv = 'COOPVAULTMTZQXVEFFDU7QPTEYT7OSEX56H37MJFF3OTIR7ZMNMSIGHVOE';
export const nftCreator = 'COOPLFOESCTQJVLSFKAA4QURNBDZGMRYJVRH7BRRREB7FFZSHIIA4AVIBE';

export const wormholeNetworks = {
  1: "Solana",
  2: "Ethereum",
  3: "Terra Classic",
  4: "BSC",
  5: "Polygon",
  6: "Avalanche",
  7: "Oasis",
  8: "Algorand",
  9: "Aurora",
  10: "Fantom",
  11: "Karura",
  12: "Acala",
  13: "Klaytn",
  14: "Celo",
  16: "Moonbeam",
  18: "Terra",
  19: "Injective",
  21: "Sui",
  22: "Aptos",
  23: "Arbitrum",
  24: "Optimism",
  28: "XPLA",
};

export const coopAid = 796425061;

// STOPBUYINGCOOPWHILETHEREISSTILLANALGORANDTOSAVEEEEEK5BX5YU
// IFWECOULDREDIRECTSOMEATTENTIONFROMCOOPWECOULDSAVEALG
// INTHEBEGINNINGTHEREWEREFIFTYNINETHOUSANDCOOPDROPPED
// ASOCIALAGREEMENTONSTORINGANDEXCHANGINVALUEONALGORAND
