import { useMemo, useState, useEffect, useCallback } from 'react';
import Tooltip from '../components/Tooltip.jsx';
import { numLocaleOptionsFull, crv, ALGO, coopAid } from '../constants.js';
import config from '../constants.json';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, VSeparator, Opaque, HFlex, HTFlex, VFlex, BigLoader, Left, } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { getCoopAssetUSDPrices, sendVote } from '../algo.js';
import Wallet from '../components/Wallets.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TxnId, TxnAddress } from '../components/Txn.jsx';
import { TableRow as RTableRow, TableCell as RTableCell } from '../components/responsive-table.js'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';
import useInterval from '../hooks/use-interval.js';
import { govStart, govEnd } from '../constants.js';
import laserBrownEye from '../images/laserbrowneye256.png';
import laserGoldenEye from '../images/goldeneye256.png';
import assets from '../assets.json';
import { printUSD, printALGO, printNumber } from '../utils.js';
import IconCopyButton from '../components/IconCopyButton.jsx';
import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import promilol from 'promilol';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// const coopAid = assets.find(({name}) => name === 'COOP').aid;
const aid = 891053373;

function downloadFile(fileName, data) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

export async function exportToCsv(data) {
  const columns = Object.keys(data[0]).join(',');
  const rows = [columns, ...data.map(row => Object.values(row).join(','))].join('\r\n');
  const fileName = 'coop-bridging-activity.csv';
  downloadFile(fileName, new Blob([rows], { type: 'text/csv;charset=utf-8;' }));
}

function RightAlignTableCell({ children, sx, ...props }) {
  const osx = { ...sx, textAlign: 'right !important' };
  return <TableCell {...props} sx={osx}>{children}</TableCell>
}

const mw = {
  xs: '380px',
  lg: '450px',
}

function Bridge({ account, bridge, assets }) {
  const navigate = useNavigate();
  const [aid, setAid] = useState(coopAid);
  const [unit, setUnit] = useState('COOP');
  const [options, setOptions] = useState(
    <MenuItem value={coopAid}>Coop Coin (COOP)</MenuItem>
  );

  useEffect(() => {
    bridge.refresh();
  }, []);

  const { loading, data, globalStats, chainStats } = bridge;

  const hasData = data && data[aid] && !!data[aid].length;

  const g = globalStats && globalStats[aid];
  const c = chainStats && chainStats[aid];

  const doExport = useCallback(() => {
    exportToCsv(data[aid]);
  }, [data, aid]);

  useEffect(() => {
    if (data) {
      (async () => {
        console.log(Object.keys(data).join(' , '));
        const assetInfo = await promilol(Object.keys(data), aid => assets.wait(Number(aid)), { concurrency: 10, returnResults: true });
        const options = assetInfo
          .sort(({value: { aid: a}}, {value:{aid:b}}) => a>b?1:-1)
          .map(({value: { aid, name, unit, ...p }}, i) => {
            if (aid === coopAid)
              return;
            return <MenuItem key={`token-${i}`} value={aid}>{name} ({unit})</MenuItem>
          }).filter(Boolean)
        setOptions([
          <MenuItem key={`token-COOP`} value={coopAid}>Coop Coin (COOP)</MenuItem>,
          ... options
        ]);
      })();
    }
  }, [data]);

  return <>
    <VSeparator />

    <Opaque sx={{pb: 2}}>
        <Typography align="center" id="#table" variant="h2">PORTAL Bridge Activity</Typography>
        <HFlex sx={{ mt: { xs: 2, md: 0 }}}>
          <Button variant="outlined" sx={{mr: 2}} onClick={() => window.location.href="https://www.portalbridge.com/#/transfer"}>PORTAL TOKEN BRIDGE</Button>
          <Button variant="outlined" onClick={() => window.location.href="https://www.portalbridge.com/#/token-origin-verifier"}>VERIFY BRIDGED TOKENS</Button>
        </HFlex>
      </Opaque>

    <VSeparator />

    <Opaque sx={{pb: 2, pt: 2, position: 'relative'}}>
      <HFlex sx={{flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between' }}>
        <FormControl size="small" sx={{mr: { xs: 0, md: 2}, mb: { xs: 2, md: 0}}}>
          <Select
            id="aid=-select"
            small
            value={aid}
            onChange={async ({ target: { value: aid }}) => {
              const { unit } = await assets.wait(aid);
            setAid(aid)
            setUnit(unit);
            }}
          >
            {options}
          </Select>
        </FormControl>
        <HFlex>
          <Typography variant="h3" sx={{display: 'inline-block', mt: '1px', mb: '-1px', mr: 1}}>
            {unit} {aid ? aid : ''}
          </Typography>
          <IconCopyButton initColor="faded" value={aid} />
          <IconButton title="Open in Allo" onClick={() => window.location.href=`https://allo.info/asset/${aid}`}>
            <OpenInNewIcon color="faded" />
          </IconButton>
        </HFlex>
      </HFlex>
    </Opaque>

    { !hasData && loading ? <>
      <VSeparator vh={1} />
          <Opaque h2><Typography variant="h3">Loading</Typography></Opaque></> : null }

    <VSeparator vh={1} />

    { hasData ?  <><VFlex><HFlex sx={{flexDirection: { xs: 'column', md: 'row', }, width: 1, justifyContent: 'space-between' }}>
    <Opaque sx={{ minWidth: mw, alignSelf: { xs: 'center', md: 'stretch' }, mb: { xs: 2, md: 0 }, justifyContent: 'flex-start' }}>
      <Typography sx={{borderBottom: '1px var(--primary) solid', textAlign: 'center', width: 1, pb: 1 }} variant="h3">Statistics</Typography>
      <Table>
        <TableBody>
          { g?.num ? <>
          <TableRow> <TableCell>Networks</TableCell> <RightAlignTableCell>{Object.keys(c).length}</RightAlignTableCell> </TableRow>
          <TableRow> <TableCell>Transactions</TableCell> <RightAlignTableCell>{g.num}</RightAlignTableCell> </TableRow>
          <TableRow> <TableCell>Users</TableCell> <RightAlignTableCell>{g.users}</RightAlignTableCell> </TableRow>
          <TableRow> <TableCell>Bridged Out ({g.out})</TableCell> <RightAlignTableCell>{printNumber(g.outSum)} {unit}</RightAlignTableCell> </TableRow>
          <TableRow> <TableCell>Bridged In ({g.in})</TableCell> <RightAlignTableCell>{printNumber(g.inSum)} {unit}</RightAlignTableCell> </TableRow>
          <TableRow> <TableCell><strong>Net Bridged ({g.num})</strong></TableCell> <RightAlignTableCell><strong>{printNumber(g.netSum)} {unit}</strong></RightAlignTableCell> </TableRow>
          </> : null }
        </TableBody>
      </Table>
    </Opaque>
    <Opaque sx={{ minWidth: mw, alignSelf: { xs: 'center', md: 'stretch' }, justifyContent: 'flex-start' }}>
      <Typography sx={{ borderBottom: '1px var(--primary) solid', pb: 1, textAlign: 'center', width: 1 }} variant="h3">Top Networks</Typography>
      <Table>
        <TableBody>
          {c?.slice(0, 3).map(({name, num, netSum, outSum, inSum}, i) => <>
            <TableRow key={`idjdhf-${i}`}> <TableCell>{name}<br /> ({num} Txns)</TableCell> <TableCell sx={{textAlign: 'left !important'}}>
              Out: {printNumber(outSum)} {unit}<br/>
              In: {printNumber(inSum)} {unit}<br />
              <strong> Net: {printNumber(netSum)} {unit} </strong>
            </TableCell> </TableRow>
          </>)}
        </TableBody>
      </Table>
    </Opaque>
  </HFlex>
    </VFlex><VSeparator vh={1} /></> : null }

            { hasData ? 
    <Opaque sx={{ width: 1 }}>
        <VFlex sx={{width: { xs: 1, lg: 0.9}}}>
          <HFlex sx={{ position: 'relative', borderBottom: '1px var(--primary) solid', height: 75, justifyContent: 'flex-end', width: 1 }}>
            <Typography align="left" sx={{position: 'absolute', left: "50%", top: '50%', width: 0.75, textAlign: 'center', transform: 'translate(-50%, -50%)', }} variant="h3">Transactions</Typography>
            <HFlex>
              <IconButton title="Reload data" disabled={loading} onClick={() => bridge.refresh(true)}>
                <ReplayIcon className={loading ? "fastspin" : ""} />
              </IconButton>
              <IconButton title="Export (CSV)" onClick={() => doExport()}>
                <DownloadIcon />
              </IconButton>
            </HFlex>
          </HFlex>

            <VFlex sx={{ width: 1 }}>
              <Table>
                <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
                  <TableRow>
                    <TableCell>Txn & Account</TableCell>
                    <TableCell>Network</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Wen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { bridge.data[aid].map(({id, account, chain, amount, ts}, i) => {
                    return <RTableRow key={`hjkldhjdx-${i}`}>
                      <RTableCell isx={{width: { xs: 1 }, justifyContent: 'space-between'}}>
                        <TxnId linkAlgoScan={true} gid={id} />
                        <TxnAddress linkAlgoScan={true} shortenL={6} address={account} />
                      </RTableCell>
                      <RTableCell isx={{ width: { xs: 1 } }}>
                        {amount > 0 ? "To" : "From"}: {chain}
                      </RTableCell>
                      <RTableCell isx={{ width: { xs: 1 } }}>
                        {printNumber(Math.abs(amount))} {unit}
                      </RTableCell>
                      <RTableCell isx={{ width: { xs: 1 } }}>
                        {new Date(ts * 1000).toLocaleString()}
                      </RTableCell>
                    </RTableRow>
                  }) }
                </TableBody>
              </Table>
            </VFlex>
        </VFlex>
    </Opaque>
    : null }

    <VSeparator />

  </>
}

export default observer(Bridge);
