import { when, runInAction, observable, reaction, } from 'mobx';
import { load, save } from './persist.js';
import debounce from 'lodash/debounce.js';

const identityFn = x => x

export default class MobxCache {
  _status = observable.map();
  _cache = observable.map();

  defaultOptions = {
    processData: identityFn,
    name: '',
    persist: false,
    version: 1,
  }

  /**
   * @onMiss: function that returns a promise resolving into the value to be inserted in the cache.
   * @options:
   * - processData: function to process data after it's received from onMiss().
   */
  constructor(options) {
    const { onMiss, ...finalOptions } = Object.assign({}, this.defaultOptions, options)
    const { name, persist } = finalOptions;
    if (persist && !name) {
      throw new Error('persist passed without localStorage key name');
    }
    if (!onMiss) {
      throw new Error('onMiss callback was not provided');
    }
    this.onMiss = onMiss;
    this.options = finalOptions;

    if (persist) {
      load(name, ({cache, version}) => {
        if (cache && version === this.options.version) {
          setTimeout(() => {
            runInAction(() => {
              this._cache = cache;
            });
          }, 1);
        }
      });

      reaction(() => this._cache.size, (newValue, prevValue) => {
        console.log('changed', name);
        this.save();
      });
    };
  }

  save = debounce(() => {
    const { name } = this.options;
    console.log('saving', name);
    save(name, { cache: this._cache, version: this.options.version });
  }, 2500)

  // Try to find the data in the cache. If it's not there, it will be fetched from the server.
  get(key) {
    this._maybeCacheMiss(key)
    return this.peek(key)
  }

  // Get whatever is in the cache. No server request.
  peek(key) {
    return {
      status: this.status(key),
      value: this.value(key),
    }
  }

  wait(key) {
    return new Promise(async resolve => {
      const { status, value } = this.peek(key);
      if (value) {
        console.log('cached data for', key);
        return resolve(value);
      }
      if (!status) {
        console.log('querying', key);
        this._maybeCacheMiss(key);
      } else {
        console.log('already queried, pending', key);
      }
      when(
        () => this._cache.get(key),
        () => resolve(this._cache.get(key)),
      );
    });
  }

  // Get the status of a key.
  status(key) {
    return this._status.get(key)
  }

  // Get the value from the cache.
  value(key) {
    return this._cache.get(key)
  }

  _maybeCacheMiss(key) {
    if (!this.status(key) && typeof this.onMiss === 'function') {
      // Cache miss!
      const value = this.onMiss(key)
      if (typeof value.then === 'function') {
        this._updateStatus(key, 'pending')
        value.then(data => this.populate(key, data))
      } else {
        this.populate(key, value)
      }
    }
  }

  populate(key, data) {
    const processData = this.options.processData || identityFn
    runInAction(() => {
      this._updateStatus(key, 'success')
      this._cache.set(key, processData(data))
    });
  }

  _updateStatus(key, status) {
    runInAction(() => {
      this._status.set(key, status)
    });
  }
}
