import { load, save } from './persist';
import { makeAutoObservable, reaction } from "mobx"
import { refreshBalances } from '../algo.js';
import MyAlgoConnect from '@randlabs/myalgo-connect';
import { lookupNFD } from '../utils.js';

const _type = 'account';

class Account {
  address = null
  rekeyTo = null
  balance = null
  availableBalance = null
  availableBalanceNum = null
  optedIn = null
  assetBalances = {}
  wallet = null;
  walletObject = null;
  NFD = null;

  constructor(newObj) {
    makeAutoObservable(this);
    load(_type, (address) => {
      setTimeout(() => {
        if (address) {
          this.setAddress(address);
          this.setWallet('myalgo');
          this.setWalletObject(new MyAlgoConnect());
        }
      }, 1);
    });
  }

  setOptedIn(value) {
    console.log('setOptedIn', value);
    this.optedIn = value;
  }

  setWallet(wallet) {
    this.wallet = wallet;
    if (wallet === "myalgo" || !wallet) {
      save(_type, this.address);
    }
  }

  setWalletObject(obj) {
    this.walletObject = obj;
  }

  setAddress(address) {
    this.address = address;
    if (address) {
      // refreshBalances(this); // TODO ?
      this.refreshNFD();
    }
  }

  async refreshNFD() {
    const results = await lookupNFD(this.address);
    const values = Object.values(results ?? {});
    if (values.length) {
      this.setNFD(values[0]);
      console.log("NFD", this.NFD);
    }
  }

  setNFD(value) {
    this.NFD = value;
  }

  setBalance(balance) {
    this.balance = balance;
  }

  setAvailableBalance(availableBalance) {
    this.availableBalance = availableBalance;
  }

  setAvailableBalanceNum(availableBalance) {
    this.availableBalanceNum = availableBalance;
  }

  setAssetBalances(assetBalances) {
    this.assetBalances = assetBalances;
  }

  disconnect() {
    this.setAddress(null);
    this.setWallet(null);
    this.setWalletObject(null);
    this.setBalance(null);
    this.setAvailableBalance(null);
    this.setOptedIn(null);
    this.setAssetBalances(null);
  }
}

export default Account;
