import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { HFlex, VFlex } from './Layout.jsx';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: { xs: 0.9, md: 0.7, lg: 0.6, xl: 0.5 },
};

function Explainer({uniqueKey, title="Explainer", children, onClose, setModalParent}) {
  const [modalOpen, setModalOpen] = useState(true);
  const [doNotShowAgain, setDoNotShowAgain] = useState(true);

  const hideModal = () => {
    setModalOpen(false);
    setModalParent(false);
    setTimeout(() => onClose(doNotShowAgain));
  };

  const toggleShowAgain = () => {
    setDoNotShowAgain(!doNotShowAgain);
  }

  return <Modal
      open={modalOpen}
      onClose={hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{width: '100%'}}
    >
      <Box sx={style}>
        <Box sx={{position: 'absolute', top: 5, right: 5}}>
          <IconButton onClick={hideModal}>
              <CloseIcon />
            </IconButton>
        </Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          <VFlex sx={{alignItems: 'flex-start'}}>
            {children}
          </VFlex>
        </Typography>
        <HFlex>
          <div style={{flexGrow: 1}}>
            <FormControlLabel className="maldini" control={<Checkbox color="primary" checked={doNotShowAgain} onChange={toggleShowAgain} />} label="Do not show again" />
          </div>
          <Button variant="outlined" onClick={hideModal}>OK</Button>
        </HFlex>
      </Box>
    </Modal>
}

export default observer(Explainer);
