import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { load, save } from './persist.js';
import { convertObjectSnakeCaseToCamelCase } from '../utils.js';
import constants from '../constants.json';
import get from 'lodash/get';

const { genesisRound } = convertObjectSnakeCaseToCamelCase(constants);

const _type = 'indexer';

const DEBUG = false;

class Indexer {
  lastRound = null;
  lastUpdated = null;
  loading = false;
  data = [];
  addressStates = {};

  get timeSinceUpdate() {
    return Date.now() - this.lastUpdated;
  }

  constructor(account) {
    this.account = account;
    makeAutoObservable(this);

    load(_type, (val) => { 
      setTimeout(async () => {
        if (val && (val.length || val.data)) {
          const data = val.data ?? val ?? [];
          if (!val.v || val.v < 3) {
            console.log('clearing old indexer');
            this.setAddressStates({});
            this.setData([]);
          } else {
            DEBUG && console.log("indexer from memory", data.length);
            this.setAddressStates(val.as);
            this.setData(data);
          }
        }
      }, 1);
    }, true);

    reaction(() => this.data, (newValue, prevValue) => {
      if (!save(_type, { data: this.data, as: this.addressStates, v: 3 })) {
        if (DEBUG) {
          const res = save(_type, { data: this.data.slice(0, 500), as: this.addressStates, v: 3 });
          console.log('retry fixed it');
        }
      }
    });
  }

  setLastRound(round) {
    this.lastRound = round;
  }

  setLoading(value) {
    this.loading = value;
  }

  setData(data) {
    this.data = data;
    this.ids = new Set(data.map(({id}) => id));
    if (data.length && data[0]) {
      const lastConfirmed = data.slice(0, 10).find(({r}) => r).r;
      if (lastConfirmed) {
        if (this.lastRound !== lastConfirmed)
          this.setLastRound(lastConfirmed)
        DEBUG && console.log("setting last round", lastConfirmed);
      }
    }
  }

  setLastUpdated(value) {
    this.lastUpdated = value;
  }

  setAddressStates(as) {
    this.addressStates = as;
  }

  async getGlobalHistoryMaybe(round) {
    if (this.timeSinceUpdate > 15_000) {
      this.getGlobalHistory();
    }
  }

  async getGlobalHistory(round) {
  }

  getYourHistory(_address) {
  }

  get leaderboard() {
  }

  getAllHistory() {
  }

  reset() {
    this.setLastRound(genesisRound);
    this.setLastUpdated(null);
    this.setAddressStates({});
    this.setData([]);
  }

}

export default Indexer;
