import promilol from 'promilol';
import { ALGO } from './constants.js';

export const snakeCaseToCamel = (str) => str.replace(/_./g, s => s[1].toUpperCase())

export const convertObjectSnakeCaseToCamelCase = (obj) => Object.entries(obj).reduce((obj, [key, value]) => {
  obj[snakeCaseToCamel(key)] = value
  return obj
}, {});

export const sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

export function shorten(str, len = 8, showEnd=true) {
  const end = len - 1
  if (str?.length > 12) {
    return str.slice(0, end)+".."+(showEnd ? str.slice(-end) : "");
  }
}

function chunk(elems, num=20) {
  return elems.reduce((out, cur) => {
    let last = out[out.length - 1];
    if (last.length == num) {
      out.push([]);
      last = out[out.length -1];
    }
    last.push(cur);
    return out;
  }, [[]]);
}

export const NFDCache = {
  // overrides
  "EZPSYVX4GG3OMUQWIJNTYAAUZAHQHSOOHEKFXFWMZ3VIFCUFSZCSABMQUM": "roamoilanen.algo",
  "DGLTCFMANL2SMLVIAZ4KC2KIXSV7NSW27Y5SR23TKF5F64R5RKRPJJKC2I": "roamoilanen.algo",
  "EGKEEEO7OJ63M2TBFATCKWH43LRWJFKNJVCAU2ROBUFLDLOMT73ZV62HGU": "roamoilanen.algo",
  "NXI7MODRDUOVA7XQK2P3HVP325P26BOBWTADLGJUUEQ7ALMOVIQBIAURWM": "probnotfishing.algo",
  "NFTCOOPERFV256YCEGSOISWKKO26LULIVDV5PC6UEJR36GWPEHF5JHYABM": "d13.algo",
  "QWEVZZT4KZEFCJ5JRFSMDLO6RMH4OVROTS2MJQ5LRTMEE3JQZVJQBDSSEQ": "pianoman.algo",
  "K76FTV3OVTYZ23WOYPXE2TFZFHDUC36RCEQYDXUN2FXWASOVCCR3WOHZSM": "pianoman.algo",
  "PACTFIIFTBWHD52WFMKTSCDZWFVWZK4ZDSWIZHMROZLYD5PZFA4TLCWP7I": "void",
  "7TBNTWOFW3DUVPK2JRADBNRTMHDGNYFO4DBAIEK2EK5O67EB3MPRJKMH3I": "void",
  "COOPLFOESCTQJVLSFKAA4QURNBDZGMRYJVRH7BRRREB7FFZSHIIA4AVIBE": "COOP NFT Collection",
};

// cachedLookupNFD
//  NFDCache -> promise || result
//  addresses -> NFDCache -> [existing, new]
//  if [new] -> NFDCache += promise
//  await Promise.all(values(NFDCache[addresses]))

export async function lookupNFD(addresses) {
  addresses = Array.isArray(addresses) ? addresses : [addresses];
  const New = []
  for(const address of addresses) {
    if (NFDCache[address] === undefined) {
      New.push(address);
    }
  }
  if (New.length) {
    const asyncRes = _lookupNFD(New);
    for(const N of New) {
      NFDCache[N] = asyncRes.then((data) => {
        return data[N];
      });
    }
  }
  const resultsE = Object.entries(NFDCache).filter(([key]) => addresses.includes(key));
  const results = {};
  for(const [resKey, resValue] of resultsE) {
    NFDCache[resKey] = results[resKey] = await resValue;
  }
  return results;
}

async function _lookupNFD(address) {
  // TODO cache not existing
  // TODO debounce / join requests
  let addresses = Array.isArray(address) ? address : [address];
  const results = Object.fromEntries(addresses.map(address => ([address, null])));
  const chunks = chunk(addresses, 20);
  await promilol(chunks, async chunk => {
    if (!chunk.length)
      return;
    const query = chunk.join('&address=');
    // console.log("Querying", ...chunk);
    const url = `https://api.nf.domains/nfd/lookup?address=${query}&view=thumbnail`;
    let text;
    try {
      const resp = await fetch(url);
      text = await resp.text();
      let json;
      if (!text.length) {
        return;
      }
      json = JSON.parse(text);
      for(const [addr, obj] of Object.entries(json)) {
        const { name } = obj;
        results[addr] = name;
      }
    } catch(e) {
      console.log('NFDomains lookup', e, text);
      return;
    }
  }, { concurrency: 4 });
  return results;
}

export function uint8ToBase64(arr) {
  if (!arr?.length)
    return ''
  return btoa(new Array(...arr).map(s => String.fromCharCode(s)).join(""));
}

// window.nfd = lookupNFD;

// all_addrs = [...new Set([...JSON.parse(localStorage.getItem('indexer')).data.map(({address: a}) => a)])]
//
export function uppercaseFirst(str) {
  if (!str)
    return str;
  if (str === "myalgo")
    return "MyAlgo";
  if (str === "algosigner")
    return "AlgoSigner";
  return str[0].toUpperCase() + str.slice(1);
}

export function objDiff(a={}, b={}, nn=true) {
  const diff = {};
  for(const [aKey, aValue] of Object.entries(a)) {
    const bValue = b[aKey];
    if (bValue !== aValue) {
      if (nn) {
        if (bValue)
          diff[aKey] = bValue;
      } else {
        diff[aKey] = bValue;
      }
    }
  }
  return diff;
}

export function printNumber(val, prefix='', noZero=false) {
  if (typeof val !== "number")
    return val;
  const valStr = val.toLocaleString(undefined, { maximumFractionDigits: 2 });
  const finalVal = valStr === '0' && noZero ? String(val) : valStr;
  return `${prefix}${finalVal}`;
}

export function printUSD(val) {
  return printNumber(val, '$');
}

export function printALGO(val) {
  return printNumber(val, ALGO);
}

export function sentenceCase(word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function openMany(links) {
  if (links.length < 2 || window.confirm('This will open '+links.length+' windows. Are you sure?\n\nYou may have to disable your popup blocker for this to work.')) {
    for(const addr of links) {
      if (!window.open(addr)) {
        alert("Failed to open all windows. Check your browser's popup blocker settings.");
        return;
      }
    }
  }
  return false;
}
