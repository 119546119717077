import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { lightGreen } from '@mui/material/colors'
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import Wallets from './Wallets';
import { VFlex, HFlex, Link } from './Layout.jsx';
import Box from '@mui/material/Box';
import { account } from '../state/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import logo from '../images/logo64.png';
import Badge from '@mui/material/Badge';
import { govEnd } from '../constants.js';
import DonateButton from './donate.jsx';

function MenuButton({ route, children, sx }) {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = () => {
    navigate(route);
  }
  const active = location.pathname.startsWith(route);
  const color = active ? 'var(--primary)' : 'transparent';
  return <Button sx={{borderBottom: `1px ${color} solid`, ...sx}} onClick={onClick}>{children}</Button>;
}

function Header() {
  const navigate = useNavigate();
  const navigateHome = () => navigate('/');
  return <AppBar position="static" sx={{pt: 1, backgroundColor: 'white', pb: 1 }}>
    <HFlex sx={{ display: 'flex', position: 'relative', flexDirection: { xs: 'column-reverse', md: 'row' }}}>
      <Button onClick={navigateHome} size='small' sx={{position: 'absolute', transform: { xs: 'translate(10%, -50%)', md: 'translate(25%, -50%)' }, top: '50%', left: 0, display: { xs: 'inline-block', md: 'none'}}}>
        <Typography className="maldini-style" color="primary" sx={{fontSize: { xs: '2rem', md: '2.5rem' }, lineHeight: '2rem', marginTop: '2px', marginBottom: '-4px'}} >
          COOP
        </Typography>
      </Button>
      <Button onClick={navigateHome} size='small' sx={{ml: 2, display: { xs: 'none', md: 'inline-block'}}}>
        <Typography className="maldini-style" color="primary" sx={{fontSize: { xs: '2rem', md: '2.5rem' }, lineHeight: '2rem', marginTop: '2px', marginBottom: '-4px'}} >
          COOP.HAIR
        </Typography>
      </Button>
      <HFlex sx={{ flexGrow: 1, alignSelf: { md: 'center' },  mt: { xs: 1, md: 0 } }}>
        <MenuButton route="/holders">HOLDERS</MenuButton>
        <MenuButton route="/donations">CRV DONORS</MenuButton>
        <MenuButton route="/CEX">CEX DRIVE</MenuButton>
        { /* <MenuButton route="/bridge">BRIDGED</MenuButton> */ }
      </HFlex>
      <Box sx={{mr: 2, ml: 1}}>
        <DonateButton />
      </Box>
    </HFlex>
  </AppBar>
}

export default Header;
