import algosdk from 'algosdk';
import constants from '../constants.json';
import { coopAid, wormholeNetworks, crv, govStart, govEnd, oxxay, contractMethodNames, contractMethodReverse, afterRoyalT, voteAddress, } from '../constants.js';
import { sleep, convertObjectSnakeCaseToCamelCase } from '../utils.js';
import indexerConfig from '../indexer.json';
import get from 'lodash/get';
import { UintArrayToHex, UintEquals, convertStringKey, convertIntKey, convertIntKey2 } from './utils.js';
import { objDiff } from '../utils.js';
import assets from '../assets.json';

const network = "mainnet";
const server = network === "testnet" ? "https://testnet-idx.algonode.cloud" : "https://mainnet-idx.algonode.cloud";
const port = 443;
const indexer = new algosdk.Indexer("", server, port);

const MAX_ATTEMPTS = 4;

const queryIndexer = async (queryObj, limit = 5000, nextToken, attempts = 1) => {
  if (nextToken) {
    queryObj.nextToken(nextToken);
  }
  if (!queryObj.query.limit && queryObj.limit) {
    queryObj.limit(limit);
  }
  // console.log("index query", JSON.stringify(queryObj.query), `nT=${nextToken}, attempts=${attempts}`);
  const initQueryObj = { ...queryObj };
  try {
    const res = await queryObj.do();
    const dataKey = ['transactions', 'assets', 'balances']
      .flatMap(s => {
        return [s, s.slice(0, s.length - 1)];
      })
      .find(key => {
        return !!res[key];
      });
    if (!dataKey) {
      console.error('No data key found in indexer results');
      return [];
    }
    const data = res[dataKey];
    if (res['next-token']) {
      await sleep(500);
      if (attempts > 3)
        limit *= 2;
      const finalData = [...data, ...await queryIndexer(queryObj, limit, res['next-token'], 1)];
      // console.log('ret', finalData.length);
      return finalData;
    }
    return data;
  } catch(e) {
    const message = e.response?.body?.message ?? e.response?.body ?? e.message;
    console.error(`Error while querying indexer, attempt ${attempts}: ${e.message}`, e);
    if (attempts < MAX_ATTEMPTS) {
      if (attempts > 2) {
        limit /= 2;
      }
      attempts++;
      if (queryObj.limit)
        queryObj.limit(limit);
      await sleep(Math.pow(attempts, 2) * 500);
      return queryIndexer(queryObj, limit, nextToken, attempts);
    } else {
      console.error(`Too many failures querying indexer`);
      throw e;
    }
  }
}
    // const { deleted, params: { name, "unit-name": unit, decimals, ...p }, ...r 
export async function getAssetInfoFallback(assetId) {
  try {
    const { "created-at-round": r, deleted } = await queryIndexer(indexer.lookupAssetByID(assetId).includeAll(true));
    const data = await queryIndexer(indexer.lookupAssetTransactions(assetId).minRound(r).maxRound(r));
    const acfg = data.map(data => data['asset-config-transaction'] ?? data['inner-txns'].find(({"created-asset-index": caid}) => {
      return caid === assetId
    })['asset-config-transaction']).find(Boolean);
    if (!acfg) {
      console.error("Fallback lookup asset ID", assetId, "failed");
    }
    const { name, decimals, "unit-name": unit } = acfg.params;
    return { name, unit, decimals, deleted, aid: assetId };
  } catch(e) {
      console.error("Fallback lookup asset ID", assetId, "failed", e.message);
  }
}

export const getDonations  = async () => {
  const queryObj = indexer.searchForTransactions()
    .address(crv)
    .addressRole('receiver')
    .limit(10_000);
  const donations = await queryIndexer(queryObj, 5000);
  return donations
}

const masterVaultAppId = 954308356;
export const getVaults = async () => {
  const queryObj = indexer.searchForTransactions()
    .applicationID(masterVaultAppId)
    .limit(7500);
  let data = await queryIndexer(queryObj, 5000);
  data = data.filter(({"application-transaction": atxn}) => {
    const fAssets = get(atxn, 'foreign-assets');
    const fAps = get(atxn, 'foreign-apps');
    return fAps?.includes(masterVaultAppId) && assets.some(({aid}) => fAssets?.includes(aid));
  }).map(({"application-transaction": atxn}) => {
    return [
      assets.find(({aid}) => atxn['foreign-assets'].includes(aid)).aid,
      atxn['application-id'],
    ]
  });
  return data;
}

export const getHolders = async (aid) => {
  const queryObj = indexer.lookupAssetBalances(aid)
    .currencyGreaterThan('0')
    .includeAll(false);
  const holders = await queryIndexer(queryObj, 5000);
  return holders
    .reduce((out, {address, amount}) => { out[address] = amount; return out }, {});
}

window.getHolders = getHolders;

export const getVoteTxns = async () => {
  const query = indexer.searchForTransactions()
    .address(voteAddress)
    .addressRole('receiver')

  const results = await queryIndexer(query);

  return results
    .reduce((out, txn) => {
      const { sender, note, round, } = txn;
      const txtNote = Buffer.from(txn['note'], 'base64').toString();
      if (!txtNote.startsWith('coophair/v1:j')) {
        return out;
      }
      if (txn["round-time"] < govStart || txn["round-time"] > govEnd) {
        return out;
      }
      try {
        const vote = JSON.parse(txtNote.slice(14));
        if (!Array.isArray(vote))
          throw new Error('Not array');
        out.push([sender, vote, txn["round-time"], txn.id]);
      } catch(e) {
        out.push([sender, false, txn["round-time"], txn.id]);
        console.log('Invalid vote', sender, txtNote);
      }
      return out;
    }, []);
}

export const getBridgings = async () => {
  const completeTransfer = 'Y29tcGxldGVUcmFuc2Zlcg==';
  const sendTransfer = 'c2VuZFRyYW5zZmVy';
  const interestingArgs = [completeTransfer, sendTransfer];


  const query = indexer.searchForTransactions()
    .txType('appl')
    .applicationID(842126029)
    .limit(5000);

  const txns = await queryIndexer(query);

  const sends = txns.filter(t => interestingArgs.includes(t['application-transaction']['application-args'][0]));

  const bridgings = sends.map(txn => {
    const { id, group, "round-time": ts, sender, "application-transaction": atxn } = txn;
    const { "application-args": args } = atxn;
    let [ method, aid, amt, destAccount, destChain ] = args;
    if (method === sendTransfer) {
      aid = parseNumber(aid);
      amt = parseNumber(amt);
      destAccount = '0x'+Buffer.from(destAccount, 'base64').toString('hex').replace(/^0+/, '');
      let chain = parseNumber(destChain);
      chain = wormholeNetworks[chain];
      return {
        aid,
        id: group,
        account: sender,
        amount: amt,
        chain,
        ts,
        destAccount,
      }
    } else {
      const atxn = get(txn, 'inner-txns.0.asset-transfer-transaction');
      const { id, sender, "round-time": ts } = txn;
      if (!atxn)
        return;
      const arg1 = Buffer.from(args[1], 'base64');
      let offset = arg1[5] * 66 + 14;
      const network = parseNumber(arg1.slice(offset, offset+2));
      offset += 43;
      const chain = wormholeNetworks[network];
      const { amount, receiver, "asset-id": aid } = atxn;
      return {
        aid,
        id: group,
        account: sender,
        amount: -amount,
        chain,
        ts,
      }
    }
  }).filter(Boolean);

  return bridgings;
}

function parseNumber(x) {
  return parseInt(Buffer.from(x, 'base64').toString('hex'), 16);
}

export const getFarms = async () => {
  const query = indexer.searchForTransactions()
    .txType('appl')
    .applicationID(1132259748)
    .limit(5000);

  const txns = await queryIndexer(query);

  const farms = txns.reduce((farms, txn) => {
    const lastArg = get(txn, 'application-transaction.application-args');
    // console.log(txn.id, lastArg.filter(Boolean).pop());
    const addr = get(txn, 'local-state-delta.0.address');
    const key = get(txn, 'local-state-delta.0.delta.0.key');
    const val = get(txn, 'local-state-delta.0.delta.0.value.bytes');
    if (addr && key == "AA==" && val) {
      // console.log(new Date(txn['round-time']*1000))
      const hexAmt = Buffer.from(val, 'base64').slice(1, 9).toString('hex');
      const staked = parseInt(hexAmt, 16);
      farms[addr] = staked;
    }
    return farms;
  }, {});

  return farms;
}

// > parseInt(b.slice(1, 9).toString('hex'), 16)

