import { useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

export default function({ value, copyIcon, initColor='primary', initLabel, size, ...props }) {
  const [color, setColor] = useState(initColor);
  const initIcon = <ContentCopyIcon className="fakeButton" color={color} fontSize={size} />;
  const [disabled, setDisabled] = useState(false);
  const [icon, setIcon] = useState(initIcon);

  const reset = () => { setColor(initColor); setDisabled(false); setIcon(initIcon); }

  const doCopy = useCallback(() => {
    try {
      navigator.clipboard.writeText(value);
      setIcon(<CheckIcon fontSize={size} />);
      setColor('success');
      setDisabled(true);
    } catch(e) {
      setColor('error');
    }
    setTimeout(reset, 2000);
  }, [value]);

  return <IconButton title="Copy" {...props} disabled={disabled} onClick={doCopy}>{icon}</IconButton>
}
