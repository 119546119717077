import algosdk from 'algosdk';
import _algodClient from './algo/algod-client.js';
import { history as Hist, fsm, account, localAppState, globalAppState, localAssets, assets } from './state/';
import { voteAddress, oxxay, redeemSelector, contractMethodNames, contractMethodReverse, ALGO, numLocaleOptions, votePrefixNS } from './constants.js';
import Button from '@mui/material/Button';
import constants from './constants.json';
import { sleep, convertObjectSnakeCaseToCamelCase, shorten } from './utils.js';
import CopyButton from './components/CopyButton.jsx';
import teamData from './teams.json';
import { signTransactionWithWallet, signTransactionsWithWallet } from './algo/wallets.js';
import notifications from './notifications.js';
import { convertStringKey, convertIntKey, parseIntResult, waitForConfirmation } from './algo/utils.js';
import Countdown from './components/Countdown.jsx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { HFlex, VFlex } from './components/Layout.jsx';
import redeem_config from './redeem.json';
import coopAssetConfigs from './assets.json';
import { getAssetInfoFallback } from './algo/indexer.js';

const { encodeObj } = algosdk;

export const algodClient = _algodClient;

let navigate;

const { winnerAsaId, runnerUpAsaId, redeemedWinnerAsaId, redeemedRunnerUpAsaId, appId: redeemAppId, applicationAddress: redeemAppAddress } = convertObjectSnakeCaseToCamelCase(redeem_config);

const { appId, storageAppId, applicationAddress, rewardsAddress, teamNftIssuance, winnerRewardsRatio, runnerupRewardsRatio } = convertObjectSnakeCaseToCamelCase(constants);

const assetCache = { 0: {
  name: "Algo",
  "unit-name": "ALGO",
  decimals: 6,
  aid: 0,
} };

export async function getAssetInfo(assetId) {
  assetId = Number(assetId);
  if (assetCache[assetId])
    return assetCache[assetId]; 
  try {
    const data = await algodClient.getAssetByID(assetId).do();
    assetCache[assetId] = { ...data.params, aid: assetId };
    return assetCache[assetId];
  } catch(e) {
    if (e.message.includes('asset does not exist')) {
      return getAssetInfoFallback(assetId);
    }
    console.error(assetId, e.message);
  }
  // TODO Expo backoff retry?
}

async function executeTxn(txn) {
  let signedTxn = await signTransactionWithWallet(account, txn);
  if (!signedTxn) return;
  try {
    notifications.info('Sending transaction', false, 'sendTx');
    const response = await algodClient.sendRawTransaction(signedTxn).do();
    notifications.closeSnackbar('sendTx');
    const { txId } = response;
    notifications.info('Transaction sent. Waiting for confirmation', 0, 'waitConfirm');
    const confirmed = await waitForConfirmation(txId);
    notifications.closeSnackbar('waitConfirm');
    handleTxnNotify(txId);
    return confirmed;
  } catch(e) {
    handleTxnError(e);
  } finally {
    notifications.closeSnackbar('sendTx');
    notifications.closeSnackbar('waitConfirm');
  }
}

window.txnn = handleTxnNotify

function handleTxnNotify(txId) {
  notifications.info(<VFlex sx={{alignItems: 'flex-start'}}>
    <HFlex sx={{mb: 0.5}}><span>Transaction confirmed {shorten(txId, 6)}</span></HFlex>
    <HFlex>
      
      <Button variant="outlined" size="small" sx={{mr: 1}} onClick={() => window.open(`https://allo.info/tx/${txId}`)}>VIEW</Button>
      <CopyButton variant="outlined" size="small" sx={{mr: 0}} value={txId}></CopyButton>
    </HFlex>
  </VFlex>, 6000, 'transaction-confirmed');
  setTimeout(() => History.getGlobalHistory(), 5_000);
}

function handleTxnError(e) {
  console.error(e);
  const contractError = parseContractError(e.message);
  const message = contractError ? <div style={{display: 'flex', flexDirection: 'column'}}>
    <span>Smart Contract Error: {contractError}</span>
    <div><Button sx={{mr: 1}} size="small" variant="outlined" color="error" onClick={() => alert(e.message)}>VIEW FULL</Button>
      <CopyButton value={e.message} size="small" color="error" variant="outlined" /></div>
  </div> : e.message;
  notifications.error(message, 0);
  throw e;
}

async function executeTxns(txns, txIdxToReturn, targetRound) {
  algosdk.assignGroupID(txns);
  let signedTxns = await signTransactionsWithWallet(account, txns);
  if (!signedTxns) return;
  const status = await algodClient.status().do();
  const beforeRound = status['last-round'];
  if (!signedTxns) return;
  if (beforeRound < targetRound) {
    const sec = Math.ceil((targetRound - beforeRound) * 3.7)
    notifications.info(<div>Waiting for round {targetRound}<br/>Should be about {sec} seconds</div>, false, 'waitRound');
    await timeRound(targetRound);
    notifications.closeSnackbar('waitRound');
  }
  try {
    // throw new Error('logic eval error  pushbytes  // "ERR TEST" ');
    if (txIdxToReturn >= txns.length) {
      throw new Error('txIdxToReturn out of bounds');
    }
    notifications.info('Sending transactions', false, 'sendTx');
    console.log('sending', signedTxns);
    const response = await algodClient.sendRawTransaction(signedTxns).do();
    notifications.closeSnackbar('sendTx');
    let txIds = [];
    if (txIdxToReturn === -1) {
      txIds = txns.map(txn => txn.txID());
    } else { 
      const idx = typeof txIdxToReturn === "undefined" ? txns.length - 1 : txIdxToReturn;
      txIds = [txns[idx].txID()];
    }
    notifications.info('Transaction sent. Waiting for confirmation', 0, 'waitConfirm');
    const result = await Promise.all(txIds.map(txId => waitForConfirmation(txId)));
    notifications.closeSnackbar('waitConfirm');
    const txId = txIds[txIds.length-1];
    handleTxnNotify(txId);
    return result;
  } catch(e) {
    handleTxnError(e);
  } finally {
    notifications.closeSnackbar('sendTx');
    notifications.closeSnackbar('waitConfirm');
  }
}

function formatAlgoPrice(num) {
  return `${ALGO}${(num / 1_000_000).toLocaleString(undefined, numLocaleOptions)}`;
}

const contractErrorRegex = /logic eval error.*assert.*byte.*\/\/ "([^"]+)"/;
function parseContractError(message) {
  const match = contractErrorRegex.exec(message);
  return match && match[1];
}

const times = [];
async function timeRound(round) {
  console.log(new Date(), 'waiting after block', round);
  const sAB = await algodClient.statusAfterBlock(round-1).do()
  const lastTime = times.length > 0 ? times[times.length-1][1] : NaN;
  const now = Date.now();
  times.push([round, now]);
  console.log(round, sAB['last-round'], new Date(), now, now - lastTime);
}

let _secsPerBlock;
let _lastRound;
let _lastBlockTs;

let benchmarkResolve;
const benchmarkPromise = new Promise(resolve => benchmarkResolve = resolve);

async function benchmarkAlgo() {
  const status = await algodClient.status().do();
  const lastRound = _lastRound = status['last-round'];
  const prevRound = Math.max(1, lastRound - 120);
  const roundDiff = lastRound - prevRound;
  const lastBlock = await algodClient.block(lastRound).do();
  const prevBlock = await algodClient.block(prevRound).do();
  const lastBlockTs = _lastBlockTs = lastBlock.block.ts;
  const prevBlockTs = prevBlock.block.ts;
  const blocksPerSec = roundDiff / (lastBlockTs - prevBlockTs);
  const secsPerBlock = _secsPerBlock = 1 / blocksPerSec;
  for(const step of [1000, 4, 4*60, 4*60*60]) {
    const t = projectTimeForRound(lastRound + step, secsPerBlock, lastRound, lastBlockTs);
    console.log(step, new Date(t*1000), 'rounds will take', (t - Date.now())/1000);
  }
  benchmarkResolve();
}

export async function projectTimeForRoundAsync(targetRound) {
  await benchmarkPromise;
  return projectTimeForRound(targetRound);
}

export function projectRoundForTime(targetTime, secsPerBlock = _secsPerBlock, lastRound = _lastRound, lastRoundTs = _lastBlockTs) {
  if (targetTime > 1000000000000)
    targetTime /= 1000;
  const timeDiff = targetTime - lastRoundTs;
  const roundDiff = timeDiff / secsPerBlock;
  return Math.round(lastRound + roundDiff);
}
window.prt = projectRoundForTime;

export function projectTimeForRound(targetRound, secsPerBlock = _secsPerBlock, lastRound = _lastRound, lastRoundTs = _lastBlockTs) {
  const elapsedSinceLastRound = Date.now() - (lastRoundTs * 1000);
  const roundDiff = targetRound - lastRound;
  const timeForRounds = secsPerBlock * roundDiff * 1000;
  const expectedTime = Math.floor(Date.now() + timeForRounds - elapsedSinceLastRound);
  return expectedTime;
}
window.pt = projectTimeForRound;

// async function main() {
//   try {
//     // const rb = await rewardsBoost();
//     // globalAppState.setState({royaltyAmount: rb});
//     // console.log("royalties", rb);
//     await benchmarkAlgo();
//     await refreshGlobalState();
//   } catch(e) {
//     console.log(e);
//     notifications.error(e.message);
//   }
// }
// 
// setTimeout(() => {
//   main();
// }, 500);

export const setNavigate = (_navigate) => navigate = _navigate;

window.n = notifications;

export const sendVote = async (...voteObj) => {
  const voteNote = `${votePrefixNS}:j${JSON.stringify(voteObj)}`;
  const note = new Uint8Array(Buffer.from(voteNote));
  const suggestedParams = await algodClient.getTransactionParams().do();
  const payTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
    suggestedParams,
    from: account.address,
    to: voteAddress,
    amount: 0,
    note,
  });
  await executeTxn(payTxn);
}
