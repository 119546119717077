import algosdk from 'algosdk';
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { load, save } from './persist.js';
import { lookupNFD } from '../utils.js';
import constants from '../constants.json';
import { coopAid } from '../constants.js';
import get from 'lodash/get';
import { getHolders } from '../algo/indexer.js';
import assets from '../assets.json';
import { getFarms, getVaults } from '../algo/indexer.js';

const _type = 'holders';

const DEBUG = false;

const expectedVersion = 2;

class Holders {
  lastUpdated = null;
  loading = false;
  holders = {};
  vaults = {};

  get timeSinceUpdate() {
    return Date.now() - this.lastUpdated;
  }

  constructor(lps) {
    this.lps = lps;
    makeAutoObservable(this);

    load(_type, (val) => { 
      if (val?.holders)
        if(val.v === expectedVersion) {
          setTimeout(() => {
            this.setHolders(val.holders);
            this.setVaults(val.vaults);
          }, 1);
        }
    }, true);

    reaction(() => this.holders, (newValue, prevValue) => {
      if (!save(_type, {
        holders: this.holders,
        vaults: this.vaults,
        v: expectedVersion,
      })) {
        console.error('err');
      }
    });
  }

  setLoading(value) {
    this.loading = value;
  }

  setHolders(holders) {
    this.holders = holders;
  }

  setVaults(data) {
    this.vaults = data;
  }

  async refreshHolders(force) {
    if (this.loading) {
      console.log('already loading');
    }
    this.setLoading(true);
    let [ vaults, lps, farms, ...assetHolders ] = await Promise.all([
      getAllVaults(),
      this.lps.refresh(force),
      getFarms(),
      ...assets.filter(({loadHolders}) => loadHolders).map(({aid}) => getHolders(aid)),
    ]);
    farms = Object.fromEntries(
      Object.entries(farms).filter(([addr, amt]) => amt)
    );

    // all owners, added vault owners, uniqued
    let humanHolders = new Set(
      vaults.map(({owner}) => owner).concat(
        assetHolders.flatMap(holders => Object.keys(holders))
      ).concat(Object.keys(farms))
    );

    const uniqueVaults = [];
    // remove vault addresses
    for(const { vault } of vaults) {
      humanHolders.delete(vault);
      uniqueVaults.push(vault);
    }
    humanHolders = [...humanHolders];

    const NFDs = await lookupNFD(humanHolders);
     // const revNFDs = invertNFD(NFDs);

    const mergedHolders = {}
    for(const vault of vaults) {
      const { asset_id, id, owner, locked } = vault;
      const mH = mergedHolders[asset_id] = (mergedHolders[asset_id] ?? {})
      const namedOwner = NFDs[owner] ?? owner;
      const data = mH[namedOwner] = (mH[namedOwner] ?? []);
      data.push({ address: owner, vaultID: id, vaulted: locked });
    }

    for(const [owner, staked] of Object.entries(farms)) {
      const mH = mergedHolders[coopAid] = (mergedHolders[coopAid] ?? {})
      const namedOwner = NFDs[owner] ?? owner;
      const data = mH[namedOwner] = (mH[namedOwner] ?? []);
      data.push({ address: owner, farming: staked });
    }

    let assetsIndex = 0;
    for(assetsIndex=0; assetsIndex<assetHolders.length; assetsIndex++) {
      const holders = assetHolders[assetsIndex];
      const { aid } = assets[assetsIndex];
      for(const [address, amount] of Object.entries(holders)) {
        if (uniqueVaults.includes(address))
          continue;
        const mH = mergedHolders[aid] = (mergedHolders[aid] ?? {});
        const namedOwner = NFDs[address] ?? address;
        const data = mH[namedOwner] = (mH[namedOwner] ?? []);
        data.push({ address, amount, });
      }
    }

    const stats = { LP: {count: 0, amount: 0 } };

    const data = Object.fromEntries(assets.filter(({loadHolders}) => loadHolders).map(({ name, aid, lp }, idx) => {
      let holders = mergedHolders[aid];
      let maxOwner;
      let maxOwned = -Infinity;
      for(const [owner, owned] of Object.entries(holders)) {
        // insert summary as first entry
        owned.unshift(owned.reduce((out, { address, vaulted, farming, amount, vaultID }) => {
          if (vaultID)
            out.vaultIDs = (out.vaultIDs ?? []).concat(vaultID);
          if (farming) {
            out.farming = (out.farming ?? 0) + farming;
            out.total += farming;
          }
          if (vaulted) {
            out.vaulted = (out.vaulted ?? 0) + vaulted;
            out.total += vaulted;
          }
          if (amount) {
            out.amount += amount;
            out.total += amount;
          }
          if (!out.addresses.includes(address)) {
            out.addresses.push(address);
          }
          if (maxOwned < out.total) {
            maxOwned = out.total;
            maxOwner = owner;
          }
          return out;
        }, {addresses: [], amount: 0, total: 0}));
      }
      for(const addr of lps) {
        if (holders[addr]) {
          delete holders[addr];
        }
      }
      const total = aid === coopAid ? 20_999_999_000_000 : Object.values(holders).reduce((sum, [{total}]) => sum + total, 0);
      for(const [first] of Object.values(holders)) {
        first.perc = first.total / total * 100;
      }
      return [ aid, holders ];
    }));

    this.setVaults(vaults);
    this.setHolders(data);
    this.setLoading(false);
  }
  
  reset() {
    this.setLastUpdated(null);
    this.setLoading(false);
    this.setAddressStates({});
    this.setHolders([]);
  }

}

export default Holders;

async function getAllVaults() {
  let vaultIDs = await getVaults();
  const data = await Promise.all(vaultIDs.map(([aid, appid]) => getVaultByID(appid)));
  return data.filter(Boolean);
}

async function getVaultByID(appId) {
  try {
    const response = await fetch(`https://free-api.vestige.fi/vault/${appId}`);
    const data = await response.json();
    const { id, owner, asset_id,  is_done, initial_amount, given_amount } = data;
    if (is_done)
      return;
    const vault = algosdk.getApplicationAddress(id);
    return { id, owner, asset_id, vault, locked: Number(initial_amount) - Number(given_amount) };
  } catch(e) {
  }
}

function invertNFD(data) {
  const kvs = Object.entries(data).filter(([k, v]) => v);
  return kvs.reduce((out, [addr, name]) => ({
    ...out,
    [name]: (out[name] ?? []).concat([addr]),
  }), {});
}
