import { useMemo, useState, useEffect, useCallback } from 'react';
import Tooltip from '../components/Tooltip.jsx';
import { numLocaleOptionsFull, crv, ALGO } from '../constants.js';
import config from '../constants.json';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, VSeparator, Opaque, HFlex, HTFlex, VFlex, BigLoader, Left, } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { getCoopAssetUSDPrices, sendVote } from '../algo.js';
import Wallet from '../components/Wallets.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TxnId, TxnAddress } from '../components/Txn.jsx';
import { TableRow as RTableRow, TableCell as RTableCell } from '../components/responsive-table.js'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';
import useInterval from '../hooks/use-interval.js';
import { govStart, govEnd } from '../constants.js';
import laserBrownEye from '../images/laserbrowneye256.png';
import laserGoldenEye from '../images/goldeneye256.png';
import assets from '../assets.json';
import { printUSD, printALGO, printNumber } from '../utils.js';
import IconCopyButton from '../components/IconCopyButton.jsx';

function RightAlignTableCell({ children, sx, ...props }) {
  const osx = { ...sx, textAlign: 'right !important' };
  return <TableCell {...props} sx={osx}>{children}</TableCell>
}

const mw = {
  xs: '380px',
  lg: '450px',
}

function Donors({ account, prices, donors }) {
  const navigate = useNavigate();

  useEffect(() => {
    donors.refreshDonors()
  }, []);

  const { loading, numDonations, totals, totalUSD } = donors;

  const totalLP = useMemo(() => {
    if (!totals || !prices?.prices)
      return
    return assets.filter(({lp}) => lp).reduce((sum, {aid, decimals}, i) => {
      const val = (totals[aid] ?? 0); // / (10 ** decimals);
      const pri = prices.prices[aid] * val;
      console.log(aid, i, pri);
      return pri + sum;
    }, 0);
  }, [prices, assets, totals]);

  return <>
    <VSeparator />

      <Opaque h2>
        <Typography align="center" id="#table" variant="h2">COOP CRV Donations</Typography>
      </Opaque>

    <VSeparator />

    { loading && !totalUSD ? 
      <>
        <VSeparator vh={1} />
        <Opaque h2><Typography variant="h3">Loading</Typography></Opaque>
        <VSeparator vh={1} />
      </> : null }


    { totalUSD ?  <><VFlex><HFlex sx={{flexDirection: { xs: 'column', md: 'row', }, width: 1, justifyContent: 'space-between' }}>
    <Opaque sx={{ minWidth: mw, alignSelf: { xs: 'center', md: 'stretch' }, mb: { xs: 2, md: 0 }, justifyContent: 'flex-start' }}>
      <Typography sx={{borderBottom: '1px var(--primary) solid', textAlign: 'center', width: 1, pb: 1 }} variant="h3">Donation Totals</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Donations</TableCell> <RightAlignTableCell>{numDonations}</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Donors</TableCell> <RightAlignTableCell>{donors.data.length}</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>USD Value</TableCell> <RightAlignTableCell>{printUSD(totalUSD)}</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>$COOP</TableCell> <RightAlignTableCell>{printNumber(totals[796425061])}</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>ALGO</TableCell> <RightAlignTableCell>{printALGO(totals[0])}</RightAlignTableCell>
          </TableRow>
          <TableRow>
            <TableCell>LPs</TableCell> <RightAlignTableCell>{printUSD(totalLP)}</RightAlignTableCell>
          </TableRow>
          { totals[31566704] ?
          <TableRow>
            <TableCell>USDC/T/x</TableCell> <RightAlignTableCell>{printUSD(totals[31566704] + totals[312769] + totals[760037151])}</RightAlignTableCell>
          </TableRow> : null }
        </TableBody>
      </Table>
    </Opaque>
    <Opaque sx={{ minWidth: mw, alignSelf: { xs: 'center', md: 'stretch' }, justifyContent: 'flex-start' }}>
      <Typography sx={{ borderBottom: '1px var(--primary) solid', pb: 1, textAlign: 'center', width: 1 }} variant="h3">CRV Account</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell sx={{textAlign: 'right !important'}}><TxnAddress sx={{display: 'inline-block'}} accountIcon={false} shortenBoth={false} noNFD={true} linkAlgoScan={true} address={crv} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>NFD</TableCell>
            <TableCell sx={{textAlign: 'right !important'}}><IconCopyButton size="small" initColor="faded" value="crv.coop.algo" />CRV.COOP.ALGO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Distributed<br/>(🌱Farms)</TableCell>
            <TableCell sx={{textAlign: 'right !important'}}>
              199,100 COOP<br />
              (🌱182,500 COOP)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>LP Provided</TableCell>
            <TableCell sx={{textAlign: 'right !important'}}>
              379,555 COOP
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Holders<br/>2/3 Multisig</TableCell>
            <TableCell sx={{textAlign: 'right !important'}}>
              D13.algo<br/>
              Fisherman.algo<br/>
              Roam.algo<br/>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Opaque>
  </HFlex>
    </VFlex><VSeparator /></> : null }

    { donors.data ? <Opaque sx={{ width: 1 }}>
              <VFlex sx={{width: { xs: 1, lg: 0.9}}}>
                <Typography sx={{borderBottom: '1px var(--primary) solid', textAlign: 'center', width: 1, pb: 1 }} variant="h3">Donation Leaderboard</Typography>
                <VFlex sx={{ width: 1 }}>
                  <Table>
                    <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
                      <TableRow>
                        <TableCell>Donor</TableCell>
                        <TableCell>Donation(s)</TableCell>
                        <TableCell>USD Value (now)</TableCell>
                      </TableRow>

                    </TableHead>
                    <TableBody>
                      { donors.data.map(({address, assets, totalUSD, perc}, i) => {
                        return <RTableRow key={`donnoo-${i}`}>
                          <RTableCell isx={{width: { xs: 1 }, justifyContent: 'space-between'}}>
                            <TxnAddress linkAlgoScan={true} address={address} />
                          </RTableCell>
                          <RTableCell isx={{ width: { xs: 1 } }}>
                            <HFlex>
                            {Object.entries(assets).map(([aid, amt], i, all) => {
                              const unit = getAssetShortName(aid);
                              const [usd, displayAmt] = getAssetAmt(aid, amt, prices);
                              const hasNext = i + 1 < all.length;
                              const prefix = usd ? '$' : '';
                              return <Typography key={`donna-${i}`} component="span">{prefix}{printNumber(displayAmt)} {unit}{hasNext?<>&nbsp;&middot;&nbsp;</>:''}</Typography>;
                            })}
                            </HFlex>
                          </RTableCell>
                          <RTableCell isx={{ width: { xs: 1 } }}>
                            {printUSD(totalUSD)}
                          </RTableCell>
                        </RTableRow>
                      }) }
                    </TableBody>
                  </Table>
                </VFlex>
              </VFlex>
      </Opaque> : null
    }

    <VSeparator />

  </>
}

function getAsset(aid) {
  return assets.find(({aid:a}) => a === aid);
}

function getAssetAmt(aid, amt, prices) {
  aid = Number(aid);
  if (aid === 0)
    return [false, amt];
  const asset = getAsset(aid);
  if (asset?.lp && prices?.prices && prices.prices[aid]) {
    return [true, prices.prices[aid] * amt];
  }
  return [false, amt];
}


function getAssetShortName(aid) {
  aid = Number(aid);
  if (aid === 0)
    return ALGO;
  const asset = getAsset(aid);
  if (!asset) return '???';
  if (asset.lp) {
    return ['(', asset.shortName ?? asset.name, ')'].join('');
  }
  return asset.shortName ?? asset.name;
}

export default observer(Donors);

/* 
        <VFlex>
          {
            donors.data.map(({ address, totalUSD }) => {
            return <div>{address} {totalUSD}</div>
          }) }
        </VFlex>
        */

/*

{ hasMore ? <VFlex>
  <HFlex>
    <Button variant="outlined" sx={{mt: 3}} onClick={() => loadMore(1)}>LOAD MORE</Button>
    <Button variant="outlined" sx={{ml: 1, mt: 3}} onClick={() => loadMore(9999)}>LOAD ALL</Button>
  </HFlex>
</VFlex> : null }

*/
