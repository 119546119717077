import { makeAutoObservable } from "mobx"

class GlobalAppState {
  round = null;
  fail = null;

  constructor(newObj) {
    makeAutoObservable(this);
  }

  setRound(round) {
    this.round = round;
  }

  setFail(fail) {
    this.fail = fail;
  }
}

export default GlobalAppState;
