import TableCellReal from '@mui/material/TableCell';
import TableRowReal from '@mui/material/TableRow';
import { HFlex } from './Layout.jsx';

export function TableRow({ children, sx }) {
  if (sx) console.log('SX', sx);
  return <TableRowReal sx={{ display: { xs: 'flex', md: 'table-row'}, mt: { xs: 1, md: 0 }, borderTop: { xs: '1px var(--primary) solid' }, flexDirection: { xs: 'column', md: '' }, ...sx }}>
    {children}
  </TableRowReal>
}

const defaultSX = {display: { xs: 'flex', md: 'table-cell' }}
export function TableCell({ children, header, sx, isx }) {
  const osx = { ...defaultSX, ...sx };
  if (!children)
    osx.display = { ...osx.display, xs: 'none' };
  return <TableCellReal sx={osx}>
    { header ? <>
      <HFlex sx={{justifyContent: 'flex-start', display: { xs: 'flex', md: 'none' }, flexGrow: 1}}>{header}</HFlex>
    </> : null }
    <HFlex sx={isx} >
    {children}
    </HFlex>
  </TableCellReal>
}


