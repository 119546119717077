import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { load, save } from './persist.js';
import { convertObjectSnakeCaseToCamelCase } from '../utils.js';
import constants from '../constants.json';
import get from 'lodash/get';
import { getDonations } from '../algo/indexer.js';
import assets from '../assets.json';
import { getCoopAssetUSDPrices, getAlgoUSDPrice } from '../http.js';

const _type = 'prices';

const DEBUG = false;

class Prices {
  lastUpdated = null;
  loading = false;
  prices = null;

  constructor(account) {
    makeAutoObservable(this);

    load(_type, (val) => { 
      if (val?.prices)
        setTimeout(() => this.setPrices(val.prices), 1);
    }, true);

    reaction(() => this.prices, (newValue, prevValue) => {
      if (!save(_type, { prices: this.prices, v: 1 })) {
        console.error('err');
      }
    });
  }

  get timeSinceUpdate() {
    return Date.now() - this.lastUpdated;
  }

  setLoading(value) {
    this.loading = value;
  }

  setPrices(prices) {
    this.prices = prices;
    this.lastUpdated = Date.now();
  }

  async refreshPrices() {
    if (this.loading) {
      console.log('already loading');
      return this.prices;
    }
    // TODO if not expired, no reload, return cached
    this.setLoading(true);
    const [algo, prices] = await Promise.all([
      getAlgoUSDPrice(),
      getCoopAssetUSDPrices(),
    ]);
    prices[0] = algo;
    prices[31566704] = 1;
    prices[312769] = 1;
    prices[760037151] = 1;
    this.setPrices(prices);
    this.setLoading(false);
    return prices;
  }
  
  reset() {
    this.setLastUpdated(null);
    this.setLoading(false);
    this.setPrices({});
  }

}

export default Prices;
